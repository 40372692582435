import { styled } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const AlertBox = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export default function SuccessAlert({ tag, onClose }: { tag: string, onClose: () => void }) {
  return <AlertBox onClose={onClose}>Allocations have been <strong>{tag}</strong> successfully!</AlertBox>
}