import { throwMappedAmount } from '../mapping/operators';
import Budget from '../models/Budget';
import Operation from '../models/Operation';
import { calculateAmount } from '../random/utils';
import { BudgetService } from './BudgetService';
import { Container } from '@ivorobioff/shared';

export default class BudgetOperationService {
  private budgetService: BudgetService;

  constructor(container: Container) {
    this.budgetService = container.get(BudgetService);
  }

  validate(budget: Budget, operation: Operation) {
    const amount = calculateAmount(budget.actualAmount, operation);
    return amount < 0 ? 'Result must be greater than, or equal to 0' : undefined;
  }

  async submit(budget: Budget, operation: Operation, { accountId }: { accountId?: string; } = {}) {
    const actualAmount = calculateAmount(budget.actualAmount, operation);

    try {
      await this.budgetService.updateAmountAsync({
        actualAmount,
        note: operation.note,
        accountId
      });
    } catch (e) {
      throwMappedAmount(e, 'actualAmount')
    }
  }
}