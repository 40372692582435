import { Observable } from "rxjs";
import { Allocation, UpdateAllocationData, SendAllocationData } from "../models/Allocation";
import { Container, HttpCommunicator } from "@ivorobioff/shared";

export class AllocationService {

  private http: HttpCommunicator;

  constructor(container: Container) {
    this.http = container.get('https');
  }

  public store(allocations: UpdateAllocationData[]): Observable<Allocation[]> {
    return this.http.put('/allocations', allocations);

  }

  public send(data: SendAllocationData[]): Observable<void> {
    return this.http.post('/allocations/send', data);
  }

  public getAll(): Observable<Allocation[]> {
    return this.http.get('/allocations');
  }

  public getAllAsync(): Promise<Allocation[]> {
    return new Promise((resolve, reject) => this.getAll().subscribe(resolve, reject));
  }
}