import { catchError } from "rxjs/operators";
import { cloneWith } from "@ivorobioff/shared";
import { Observable, throwError } from "rxjs";

export function mapAmountError<T>(field: string): (origin: Observable<T>) => Observable<T> {
  return catchError(error => {
    if (typeof error === 'object') {
      error = cloneWith(error, { amount: error[field] });
    }

    return throwError(error);
  });
}

type MappableField = 'plannedAmount' | 'availableAmount' | 'actualAmount';
type AmountError = Record<MappableField, string>;
const isAmountError = (error: unknown, field: MappableField): error is AmountError =>
  typeof error === 'object' && error !== null && field in error;

export function throwMappedAmount(error: unknown, field: MappableField): never {
  if (isAmountError(error, field)) {
    error = { ...error, amount: error[field] };
  }
  throw error;
}