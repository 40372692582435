import Operation, { OperationIntent } from '../models/Operation';

export function fixedAmount(value: number | string): number {
  if (value == null || typeof value === 'undefined') {
    return value;
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return parseFloat(value.toFixed(2));
}

export function isSmallScreen() {
  const width = window.innerWidth || document.documentElement.clientWidth;

  return width < 960;
}

export function calculateAmount(providedAmount: number, operation: Operation) {
  if (operation.intent === OperationIntent.Plus) {
    return fixedAmount(providedAmount + operation.amount);
  }

  if (operation.intent === OperationIntent.Minus) {
    return fixedAmount(providedAmount - operation.amount);
  }

  return operation.amount;
}
