import React, { useEffect, useState } from 'react';
import { DataPaper, Container } from '@ivorobioff/shared';
import { Box, Button, LinearProgress, Typography, styled } from '@material-ui/core';
import AllocationForm, { EditData, SendData } from './AllocationForm';
import ActionBar from './ActionBar';
import { Allocation, AllocationStub, SendAllocationData, UpdateAllocationData, createAllocationStub, isAllocationStub } from '../../../models/Allocation';
import AccountService from '../../../services/AccountService';
import Account from '../../../models/Account';
import { AllocationService } from '../../../services/AllocationService';
import { DataMutationMode } from './enums';
import SuccessAlert from './SuccessAlert';
import { BudgetService } from '../../../services/BudgetService';
import { Alert } from '@material-ui/lab';
import Budget from '../../../models/Budget';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Controls = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const AllocationWarning = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const LeftTextSpacer = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(0.5)
}));

export interface AllocationPageProps {
  container: Container;
}

function createUpdateAllocationData(data: EditData, allocation: AllocationStub | Allocation) {
  return {
    id: isAllocationStub(allocation) ? null : allocation.id,
    source: data.source,
    accountId: data.account,
    amount: data.amount ? Number(data.amount) : undefined,
    retained: data.retained
  };
}

function createSendAllocationData(data: SendData) {
  return {
    allocationId: data.allocation,
    amount: data.amount ? Number(data.amount) : undefined
  }
}

function resolveUnpreparedAccountIds(budgets: Budget[], allocations: Allocation[]) {
  const affectedAccountIds: string[] = [];

  for (const { actualAmount, accountId } of budgets) {
    if (actualAmount > 0) {
      affectedAccountIds.push(accountId);
    }
  }

  const unpreparedAccountIds: string[] = [];

  for (const { accountId } of allocations) {
    if (affectedAccountIds.includes(accountId)) {
      unpreparedAccountIds.push(accountId);
    }
  }

  return unpreparedAccountIds;
}

function makeUnpreparedAccountsMessage(unpreparedAccountIds: string[], accounts: Account[]) {
  const accountNames: string[] = [];

  for (const { id, name } of accounts) {
    if (unpreparedAccountIds.includes(id)) {
      accountNames.push(name);
    }
  }

  const firstAccountName = accountNames.shift();
  const lastAccountName = accountNames.pop();

  return <>Please reset the following accounts to allocate funds:
    <LeftTextSpacer><strong>{firstAccountName}</strong></LeftTextSpacer>
    {accountNames.map((name) => <>,<LeftTextSpacer><strong>{name}</strong></LeftTextSpacer></>)}
    {lastAccountName && <LeftTextSpacer>and<LeftTextSpacer><strong>{lastAccountName}</strong></LeftTextSpacer></LeftTextSpacer>}
  </>;
}

export default function AllocationPage({ container }: AllocationPageProps) {
  const accountService = container.get(AccountService);
  const allocationService = container.get(AllocationService);
  const budgetService = container.get(BudgetService);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [mode, setMode] = useState(DataMutationMode.Send);
  const [allocations, setAllocations] = useState<(Allocation | AllocationStub)[]>([]);
  const [originalAllocations, setOriginalAllocations] = useState<(Allocation | AllocationStub)[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [clearErrors, setClearErrors] = useState<DataMutationMode | boolean>(false);
  const [fetching, setFetching] = useState(true);
  const [doing, setDoing] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [failures, setFailures] = useState<string[]>([]);
  const [unpreparedAccountIds, setUnpreparedAccountIds] = useState<string[]>([]);
  const [budgets, setBudgets] = useState<Budget[]>([]);

  const transientData: Record<string, UpdateAllocationData | SendAllocationData> = {};

  useEffect(() => {
    (async () => {
      const [accounts, allocations, budgets] = await Promise.all([

        accountService.getAllAsync(),
        allocationService.getAllAsync(),
        budgetService.getAllAsync()
      ]);

      setAccounts(accounts);
      setAllocations(allocations);
      setBudgets(budgets);
      setOriginalAllocations(allocations);
      setMode(allocations.length === 0 ? DataMutationMode.Edit : DataMutationMode.Send);
      setRefresh(true);
      setFetching(false);

      setUnpreparedAccountIds(resolveUnpreparedAccountIds(budgets, allocations));

    })();
  }, []);

  const hasAnyAllocation = originalAllocations.length > 0 || allocations.length > 0;

  return <><DataPaper>
    <Typography component="h2" variant="h6" color="primary">Allocation</Typography>
    <br />
    <Controls>
      {!fetching && !hasAnyAllocation && <Box textAlign="center" mb={3}>
        <Button variant="outlined" color="primary" size="large" onClick={() => {
          setAllocations([createAllocationStub()]);
          setSuccess(null);
        }}>
          Create Allocations
        </Button>
      </Box>}
      {success && !hasAnyAllocation && <SuccessAlert onClose={() => setSuccess(null)} tag={success} />}
      {unpreparedAccountIds.length > 0 && <AllocationWarning icon={<ErrorOutlineIcon />} color="warning">{makeUnpreparedAccountsMessage(unpreparedAccountIds, accounts)}</AllocationWarning>}
      {allocations.map(allocation =>
        <AllocationForm key={allocation.id}
          submit={submit}
          refresh={refresh}
          onRefresh={() => {
            setRefresh(false);
          }}
          mode={mode}
          onSubmit={(data) => {
            Object.assign(transientData, {
              [allocation.id]: mode === DataMutationMode.Edit
                ? createUpdateAllocationData(data as EditData, allocation)
                : createSendAllocationData(data as SendData)
            })

            const submittedData = Object.values(transientData);

            if (submittedData.length === allocations.length) {

              setSubmit(false);
              setDoing(true);
              setSuccess(null);

              if (mode === DataMutationMode.Edit) {
                allocationService.store(submittedData as UpdateAllocationData[])
                  .subscribe(updatedAllocations => {
                    setMode(DataMutationMode.Send);
                    setAllocations(updatedAllocations);
                    setOriginalAllocations(updatedAllocations);
                    setUnpreparedAccountIds(resolveUnpreparedAccountIds(budgets, updatedAllocations));
                    setRefresh(true);
                    setDoing(false);
                    setSuccess('stored');
                  });
              } else {
                allocationService.send(submittedData as SendAllocationData[])
                  .subscribe(() => {
                    setRefresh(true);
                    setDoing(false);
                    setSuccess('send');
                  });
              }
            }
          }}
          accounts={accounts}
          allocation={allocation}
          onAdd={() => {
            setAllocations(allocations => [...allocations.flatMap(a =>
              [a, ...(a.id === allocation.id ? [createAllocationStub()] : [])])]);

            setClearErrors(DataMutationMode.Send);
            setMode(DataMutationMode.Edit);
          }}
          onDelete={() => {
            if (failures.includes(allocation.id)) {
              setFailures(failures => failures.filter(failure => failure !== allocation.id));
            }

            setClearErrors(DataMutationMode.Send);
            setMode(DataMutationMode.Edit);

            setAllocations(allocations => allocations.filter(({ id }) => id !== allocation.id));
          }}
          onError={hasErrors => {
            if (hasErrors) {
              setSubmit(false);

              if (!failures.includes(allocation.id)) {
                setFailures(failures => [...failures, allocation.id]);
              }

            } else if (failures.includes(allocation.id)) {
              setFailures(failures => failures.filter(failure => failure !== allocation.id));
            }
          }}
          clearErrors={clearErrors}
          onClearErrors={() => setClearErrors(false)}
          onDirty={(isDirty, forMode) => {
            if (isDirty && forMode === DataMutationMode.Edit && mode !== DataMutationMode.Edit) {
              setMode(DataMutationMode.Edit);
              setClearErrors(DataMutationMode.Send);
            }
          }} />)}

      {fetching && <>
        <br />
        <LinearProgress color="secondary" />
      </>}
      {hasAnyAllocation && <ActionBar
        mode={mode}
        doing={doing}
        canSave={failures.length === 0 && !fetching}
        onSave={() => {
          if (allocations.length > 0) {
            setSubmit(true);
          } else {
            setSuccess(null);
            allocationService.store([])
              .subscribe(() => {
                setAllocations([]);
                setOriginalAllocations([]);
                setDoing(false);
                setSuccess('removed');
              });
          }
        }}
        canCancel={originalAllocations.length > 0 && !doing}
        onCancel={() => {
          setFailures([]);
          setAllocations(originalAllocations);
          setRefresh(true);
          setMode(DataMutationMode.Send);
        }}
        canSend={failures.length === 0 && !fetching && unpreparedAccountIds.length === 0}
        onSend={() => {
          setSubmit(true);
        }}>{success && <SuccessAlert onClose={() => setSuccess(null)} tag={success} />}</ActionBar>}

    </Controls>
  </DataPaper>
  </>;
}