import { Container } from '@ivorobioff/shared';
import Operation, { OperationIntent } from '../models/Operation';
import Plan, { PlanAmountsToUpdate, } from '../models/Plan';
import PlanService from './PlanService';
import { calculateAmount, fixedAmount } from '../random/utils';
import { throwMappedAmount } from '../mapping/operators';
import Account from '../models/Account';
import BudgetOperationService from './BudgetOperationService';
import { BudgetService } from './BudgetService';
import { ReturnedToBudget, SubmittedForAvailable, SubmittedForBoth, SubmittedForPlanned, TransferredToPlan, TransferToAccount, TransferToPlan } from '../models/PlanOperation';

interface SubmitForAvailableParams {
  accountId?: string;
}

interface TransferToAccountParams {
  accounts: Account[];
}

export default class PlanOperationService {
  private planService: PlanService;
  private budgetOperationService: BudgetOperationService;
  private budgetService: BudgetService;

  constructor(container: Container) {
    this.planService = container.get(PlanService);
    this.budgetOperationService = container.get(BudgetOperationService);
    this.budgetService = container.get(BudgetService);
  }

  async submitForAvailable(plan: Plan, operation: Operation, { accountId }: SubmitForAvailableParams = {}): Promise<SubmittedForAvailable> {
    let availableAmount = calculateAmount(plan.availableAmount, operation);
    let plannedAmount = plan.plannedAmount;

    if (availableAmount < 0) {
      const missingAmount = Math.abs(availableAmount);
      plannedAmount = fixedAmount(plannedAmount + missingAmount);
      availableAmount = fixedAmount(plan.availableAmount + missingAmount);

      await this.planService.updateAmountsAsync(plan.id, {
        plannedAmount,
        availableAmount,
        note: operation.note,
        accountId
      });

      availableAmount = 0;
    }

    if (availableAmount > plannedAmount) {
      plannedAmount = availableAmount;
    }

    let checkpointAmount = plan.checkpointAmount;

    if (availableAmount > plan.availableAmount) {
      checkpointAmount = plannedAmount;
    }

    const amount: PlanAmountsToUpdate = {
      availableAmount,
      plannedAmount,
      checkpointAmount,
      note: operation.note,
      accountId
    };

    try {
      await this.planService.updateAmountsAsync(plan.id, amount);
    } catch (e) {
      throwMappedAmount(e, 'availableAmount');
    }

    return { plannedAmount, availableAmount, checkpointAmount };
  }

  validateForPlanned(plan: Plan, operation: Operation): string | undefined {
    const plannedAmount = calculateAmount(plan.plannedAmount, operation);

    if (plannedAmount < 0) {
      return 'Result must be greater than, or equal to 0';
    }

    const diffAmount = fixedAmount(plannedAmount - plan.plannedAmount);

    const availableAmount = fixedAmount(plan.availableAmount + diffAmount);

    return availableAmount < 0 ? 'Available Amount will be less than 0' : undefined;
  }

  async submitForPlanned(plan: Plan, operation: Operation): Promise<SubmittedForPlanned> {
    const plannedAmount = calculateAmount(plan.plannedAmount, operation);
    const checkpointAmount = plannedAmount;

    const differenceAmount = fixedAmount(plannedAmount - plan.plannedAmount);
    const availableAmount = fixedAmount(plan.availableAmount + differenceAmount);

    const amounts: PlanAmountsToUpdate = {
      plannedAmount,
      checkpointAmount,
      availableAmount,
      note: operation.note,
    };

    try {
      await this.planService.updateAmountsAsync(plan.id, amounts);
    } catch (e) {
      throwMappedAmount(e, 'plannedAmount');
    }

    return {
      plannedAmount,
      differenceAmount,
      availableAmount,
      checkpointAmount
    };
  }

  async submitForBoth(plan: Plan, operation: Operation): Promise<SubmittedForBoth> {
    const availableAmount = calculateAmount(plan.availableAmount, operation);

    if (availableAmount < 0) {
      throw new Error('Available Amount must be greater or equal to 0');
    }

    const plannedAmount = calculateAmount(plan.plannedAmount, operation);

    if (plannedAmount < 0) {
      throw new Error('Planned Amount must be greater or equal to 0');
    }

    const checkpointAmount = plannedAmount;

    await this.planService.updateAmountsAsync(plan.id, {
      availableAmount,
      plannedAmount,
      checkpointAmount,
      note: operation.note
    });

    return { plannedAmount, availableAmount, checkpointAmount };
  }

  async transferToAccount(
    { sourceAccountId, targetAccountId, targetAmount, sourceAmount }: TransferToAccount,
    { accounts }: TransferToAccountParams): Promise<void> {
    const sourceAccount = accounts.find(({ id }) => id === sourceAccountId)!;
    const targetAccount = accounts.find(({ id }) => id === targetAccountId)!;

    const toTitle = `to ${targetAccount.name}`;
    const fromTitle = `from ${sourceAccount.name}`;

    const [sourcePlans, budget] = await Promise.all([
      this.planService.getAllAsync(sourceAccountId),
      this.budgetService.getAsync({ accountId: targetAccountId }),
    ]);

    let sourcePlan = sourcePlans.find(({ name }) => name === toTitle);

    if (!sourcePlan) {
      sourcePlan = await this.planService.createAsync({
        name: toTitle,
        accountId: sourceAccountId,
        plannedAmount: 0,
        availableAmount: 0,
        suppressOverLimit: true
      });
    }

    await Promise.all([
      this.submitForAvailable(sourcePlan, {
        amount: sourceAmount,
        intent: OperationIntent.Minus
      }, {
        accountId: sourceAccountId
      }),
      this.budgetOperationService.submit(budget, {
        amount: targetAmount,
        intent: OperationIntent.Plus,
        note: fromTitle
      }, { accountId: targetAccountId })
    ]);
  }

  returnToBudget(plan: Plan, amount: number): Promise<ReturnedToBudget> {
    return this.submitForBoth(plan, {
      amount,
      intent: OperationIntent.Minus,
      note: 'Return to Budget'
    });
  }

  async transferToPlan({
    sourcePlan,
    amount,
    targetPlan,
    targetToCreate
  }: TransferToPlan): Promise<TransferredToPlan> {

    let isNewTarget = !targetPlan;

    if (!targetPlan) {

      if (!targetToCreate) {
        throw new Error('Target to create is not provided');
      }

      targetPlan = await this.planService.createAsync({
        name: targetToCreate.name,
        availableAmount: 0,
        plannedAmount: 0,
        repeat: false
      });
    }

    const [targetAmounts, sourceAmounts] = await Promise.all([
      this.submitForBoth(targetPlan, {
        amount,
        intent: OperationIntent.Plus,
        note: `Transfer from ${sourcePlan.name}`
      }),
      this.submitForBoth(sourcePlan, {
        amount,
        intent: OperationIntent.Minus,
        note: `Transfer to ${targetPlan.name}`
      })
    ]);

    if (isNewTarget) {
      Object.assign(targetPlan, targetAmounts);
    }

    return { targetAmounts, sourceAmounts, targetPlan };
  }
}