import { Box, createStyles, Paper, Theme, withStyles } from '@material-ui/core';
import React, { Component, ReactElement } from 'react';
import User from '../../../models/User';

interface UserInfoProps {
    user: User;
    control: ReactElement;
    classes: {[name: string]: string}
}

interface UserInfoState {

}

const styles = (theme: Theme) => createStyles({
    wrapper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    }
})

class UserInfo extends Component<UserInfoProps, UserInfoState> {
    
    render() {
        return (<Paper className={this.props.classes.wrapper} variant="outlined">
            <Box display={'flex'}>
            <Box flexGrow={1}>
                <Box fontWeight={'fontWeightBold'}>{this.props.user.name}</Box>
                <Box>{this.props.user.email}</Box>
            </Box>
            <Box>
                {this.props.control}
            </Box>
            </Box>
        </Paper>);
    }
}

export default withStyles(styles)(UserInfo);
