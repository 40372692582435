import React from 'react';
import {
  DataForm,
  DataFormResult,
  toNumber,
  toNullIfBlank,
  PopupFormComposite,
  singleton
} from '@ivorobioff/shared';
import { ExchangeRateAmountForm } from '../parts/ExchangeRateAmountForm';
import { Box } from '@material-ui/core';
import Preference from '../../models/Preference';
import Operation, { OperationIntent } from '../../models/Operation';;

export type AmountEditorHandler = (operation: Operation) => Promise<any>;
export type AmountEditorValidator = (operation: Operation) => string | undefined;

export interface AmountEditorProps {
  open: boolean;
  onHandle: AmountEditorHandler;
  onValidate?: AmountEditorValidator;
  onClose: () => void;
  title: string;
  defaultIntent?: OperationIntent;
  preference?: Preference;
}

function amountIntents(): { [name: string]: string } {
  return {
    [OperationIntent.Minus]: 'Minus',
    [OperationIntent.Plus]: 'Plus',
    [OperationIntent.AsIs]: 'As is',
  }
}

const DEFAULT_INTENT = OperationIntent.Minus;

export default function AmountEditor({
  open,
  title,
  onClose,
  preference,
  defaultIntent,
  onHandle,
  onValidate
}: AmountEditorProps) {

  const controls = [{
    type: 'select',
    name: 'intent',
    label: 'Intent',
    convertOut: toNumber,
    values: amountIntents(),
    value: defaultIntent || DEFAULT_INTENT
  }, {
    type: 'text',
    name: 'note',
    label: 'Note',
    convertOut: toNullIfBlank,
    extra: {
      multiline: true
    }
  }];

  const submit = (data: DataFormResult) => {
    return singleton((done, reject) => onHandle(data as Operation)
      .then(done)
      .catch(reject))
  };

  const validate = (data: DataFormResult) => {

    if (onValidate) {

      const error = onValidate(data as Operation);

      if (error) {
        return { amount: error }
      }
    }

    return {};
  };

  return <PopupFormComposite
    elements={[
      {
        type: 'form',
        component: props => <ExchangeRateAmountForm target="amount" preference={preference}  {...props} />
      },
      {
        type: 'custom',
        component: <Box m={2} />
      },
      {
        type: 'form',
        component: props => <DataForm {...props} controls={controls} />
      }
    ]}
    title={title}
    open={open}
    onClose={onClose}
    onSubmit={submit}
    onValidate={validate} />
}