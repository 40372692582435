import Budget, { BudgetAmountToUpdate, BudgetSelector } from "../models/Budget";
import { Observable } from "rxjs";
import { HttpCommunicator, Container } from "@ivorobioff/shared";

export class BudgetService {

  private http: HttpCommunicator;

  constructor(container: Container) {
    this.http = container.get('https');
  }

  public get(selector?: BudgetSelector): Observable<Budget> {
    return this.http.get('/budget', selector);
  }

  public getAsync(selector?: BudgetSelector): Promise<Budget> {
    return new Promise((resolve, reject) => this.get(selector).subscribe(resolve, reject));
  }

  public updateAmount(data: BudgetAmountToUpdate): Observable<any> {
    return this.http.patch('/budget', data);
  }

  public updateAmountAsync(data: BudgetAmountToUpdate): Promise<void> {
    return new Promise((resolve, reject) =>
      this.updateAmount(data).subscribe(resolve, reject));
  }

  public verify(): Observable<number> {
    return this.http.post('/budget/verify');
  }

  public getAllAsync(): Promise<Budget[]> {
    return new Promise((resolve, reject) => this.http.get<Budget[]>('/budget/all')
      .subscribe(resolve, reject));
  }
}