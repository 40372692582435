import Debt from '../models/Debt';
import Operation from '../models/Operation';
import { calculateAmount } from '../random/utils';
import { DebtService } from './DebtService';
import { Container } from '@ivorobioff/shared';

export default class DebtOperationService {
  private debtService: DebtService;

  constructor(container: Container) {
    this.debtService = container.get(DebtService);
  }

  validate(debt: Debt, operation: Operation) {
    return calculateAmount(debt.amount, operation) < 0
      ? 'Result must be greater than, or equal to 0'
      : undefined
  }

  async submit(debt: Debt, operation: Operation): Promise<number> {
    const calculatedAmount = calculateAmount(debt.amount, operation);

    await this.debtService.updateAmountAsync(debt.id, calculatedAmount, operation.note);

    return calculatedAmount;
  }
}