import React, { useEffect, useState } from 'react';
import Plan from '../../../models/Plan';
import { Box, LinearProgress, styled, Theme, Typography } from '@material-ui/core';

const TrackingBar = styled(Box)<Theme, { exceeds: number }>(({ exceeds }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(exceeds ? { cursor: 'pointer' } : {})
}));

export interface ExpenseProgressProps {
  plan: Plan;
}

export default function ExpenseProgress({
  plan: { plannedAmount, availableAmount, checkpointAmount, name, suppressOverLimit }
}: ExpenseProgressProps) {

  const [percentageMode, setPercentageMode] = useState(true);

  let difference = NaN;
  let progress = 100;

  if (plannedAmount > 0) {
    progress = Math.round((availableAmount * 100) / plannedAmount);
  }

  const exceeds = !suppressOverLimit && plannedAmount > checkpointAmount;

  if (exceeds) {
    difference = plannedAmount - checkpointAmount;
    progress = checkpointAmount > 0 ? Math.ceil((difference * 100) / checkpointAmount) : 100;
  }

  useEffect(() => {
    if (!exceeds) {
      setPercentageMode(true);
    }
  }, [exceeds]);

  const qualifier = exceeds ? -1 : 1;

  return (<>
    <span>{name}</span>
    <TrackingBar exceeds={exceeds ? 1 : 0}
      onClick={exceeds ? () => setPercentageMode(!percentageMode) : undefined}>
      <Box width="100%" mr={1}>
        <LinearProgress color={exceeds ? 'secondary' : 'primary'}
          variant="determinate" value={progress > 100 ? 100 : progress} />
      </Box>
      <Box minWidth={35}>
        <Typography color={exceeds ? 'secondary' : 'textSecondary'}
          variant="body2">{percentageMode
            ? `${progress * qualifier}%`
            : difference * qualifier}</Typography>
      </Box>
    </TrackingBar>
  </>);
}