import { cloneArray, cloneArrayExcept, cloneArrayWith, cloneWith, Confirmation, Container, DataActionArea, DataFormControl, DataFormResult, DataPaper, DataView, DataViewAction, DataViewColumn, PopupForm, transferTo } from '@ivorobioff/shared';
import React, { Component, Fragment } from 'react';
import { AiFillDelete, AiOutlineCheckCircle, AiOutlineEdit, AiOutlineUserAdd } from 'react-icons/ai';
import { tap } from 'rxjs/operators';
import Account, { AccountToCreate, AccountToUpdate } from '../../../models/Account';
import AccountService from '../../../services/AccountService';
import PreferenceService from '../../../services/PreferenceService';
import SearchUserPopup from './SearchUserPopup';

interface AccountPageProps {
  container: Container;
}

interface AccountPageState {
  data: Account[];
  create?: {
    open: boolean;
    controls: DataFormControl[];
  };
  edit?: {
    open: boolean;
    account: Account,
    controls: DataFormControl[]
  },
  remove?: {
    account: Account;
    open: boolean;
  },
  link?: {
    account: Account,
    open: boolean
  }
}

class AccountPage extends Component<AccountPageProps, AccountPageState> {

  private accountService: AccountService;
  private preferenceService: PreferenceService;

  columns: DataViewColumn[] = [
    {
      name: 'name',
      component: (account: Account) => (account.current
        ? <strong>{account.name}</strong>
        : <Fragment>{account.name}</Fragment>)
    }
  ];

  actions: DataViewAction[] = [{
    icon: <AiOutlineEdit />,
    onClick: this.openEditor.bind(this),
  }, {
    icon: <AiOutlineCheckCircle />,
    onClick: this.activate.bind(this),
    disabled: (account: Account) => account.current
  }, {
    icon: <AiOutlineUserAdd />,
    onClick: this.openLinker.bind(this),
  }, {
    icon: <AiFillDelete />,
    onClick: this.openRemoveConfirmation.bind(this),
    disabled: (account: Account) => account.locked
  }];

  constructor(props: AccountPageProps) {
    super(props);

    this.state = {
      data: []
    };

    this.accountService = props.container.get(AccountService);
    this.preferenceService = props.container.get(PreferenceService);
  }

  componentDidMount() {
    this.refreshAccounts();
  }

  private refreshAccounts() {
    this.accountService.getAll().subscribe(data => {
      this.setState({ data })
    }, console.error);
  }

  private openCreator() {
    this.setState({
      create: cloneWith(this.state.create, {
        open: true,
        controls: this.defineCreatorControls()
      })
    });
  }

  private closeCreator() {
    this.setState({
      create: cloneWith(this.state.create, {
        open: false
      })
    });
  }

  private submitCreator(result: DataFormResult) {
    return this.accountService.create(result as AccountToCreate).pipe(
      tap(account => {
        this.setState({
          data: cloneArrayWith(this.state.data, account)
        });
      })
    );
  }

  private openEditor(account: Account) {
    this.setState({
      edit: cloneWith(this.state.edit, {
        open: true,
        account,
        controls: this.defineEditorControls(account)
      })
    });
  }

  private closeEditor() {
    this.setState({
      edit: cloneWith(this.state.edit, {
        open: false
      })
    });
  }

  private submitEditor(result: DataFormResult) {
    let account = this.state.edit!.account;

    return this.accountService.update(account.id, result as AccountToUpdate).pipe(
      tap(() => {
        transferTo(result, account);
        this.setState({ data: cloneArray(this.state.data) });
      })
    );
  }

  private defineCreatorControls(): DataFormControl[] {
    return [{
      type: 'text',
      label: 'Name',
      name: 'name',
      required: true
    }];
  }

  private defineEditorControls(account: Account): DataFormControl[] {
    return [{
      type: 'text',
      label: 'Name',
      name: 'name',
      value: account.name,
      required: true
    }];
  }

  private closeRemoveConfirmation() {
    this.setState({
      remove: cloneWith(this.state.remove, {
        open: false
      })
    })
  }

  private handleRemoveConfirmation() {
    let account = this.state.remove!.account;
    return this.accountService.remove(account.id).pipe(
      tap(() => {
        if (account.current) {
          this.refreshAccounts();
        } else {
          this.setState({
            data: cloneArrayExcept(this.state.data, account)
          });
        }
      })
    );
  }

  private openRemoveConfirmation(account: Account) {
    this.setState({
      remove: cloneWith(this.state.remove, {
        open: true,
        account
      })
    })
  }

  private activate(account: Account) {

    let currentAccount = this.state.data.find(a => a.current)!;
    currentAccount.current = false;
    account.current = true;
    this.setState({ data: cloneArray(this.state.data) });
    this.preferenceService.dropCache();

    this.accountService.activate(account.id).subscribe(_ => { }, console.error);
  }

  private openLinker(account: Account) {
    this.setState({
      link: cloneWith(this.state.link, {
        account,
        open: true
      })
    })
  }

  private closeLinker() {
    this.setState({
      link: cloneWith(this.state.link, {
        open: false
      })
    })
  }

  private onLinked() {
    this.refreshAccounts();
  }

  render() {

    const { data } = this.state;

    return (<Fragment>
      <DataPaper>
        <DataView
          title="Accounts"
          data={data}
          actions={this.actions}
          columns={this.columns} />
        <DataActionArea onCreate={this.openCreator.bind(this)} />
      </DataPaper>
      {this.state.create && (<PopupForm
        controls={this.state.create?.controls}
        onClose={this.closeCreator.bind(this)}
        onSubmit={this.submitCreator.bind(this)}
        open={this.state.create?.open}
        title="Account - Create" />)}
      {this.state.edit && (<PopupForm
        controls={this.state.edit?.controls}
        onClose={this.closeEditor.bind(this)}
        onSubmit={this.submitEditor.bind(this)}
        open={this.state.edit?.open}
        title="Account - Update" />)}

      {this.state.remove && (<Confirmation
        onClose={this.closeRemoveConfirmation.bind(this)}
        onHandle={this.handleRemoveConfirmation.bind(this)}
        confirmButtonTitle="Proceed"
        open={this.state.remove!.open}
        title={`${this.state.remove!.account.name} - Delete`}>
        {`You are about to delete "${this.state.remove!.account.name}". Do you want to proceed?`}
      </Confirmation>)}
      {this.state.link && <SearchUserPopup
        open={this.state.link!.open}
        onClose={this.closeLinker.bind(this)}
        account={this.state.link!.account}
        container={this.props.container}
        onSubmit={this.onLinked.bind(this)}
      />}
    </Fragment>);
  }
}

export default AccountPage;