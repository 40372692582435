import React, { useEffect, useState } from 'react';
import { checkMoney, Container, Popup } from '@ivorobioff/shared';
import { combineLatest, Subject } from 'rxjs';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import AccountService from '../../../services/AccountService';
import { UserService } from '../../../services/UserService';
import Account from '../../../models/Account';
import { Controller, useForm } from 'react-hook-form';
import { fixedAmount } from '../../../random/utils';
import PlanOperationService from '../../../services/PlanOperationService';

export interface MakeTransferPopupProps {
  open: boolean;
  onClose: () => void;
  container: Container;
  onTransfer: () => void;
}

interface FormData {
  sourceAccount: string;
  sourceAmount: string;
  targetAccount: string;
  targetAmount: string;
}

export default function MakeTransferPopup({ open, onClose, container, onTransfer }: MakeTransferPopupProps) {
  const accountService = container.get(AccountService);
  const userService = container.get(UserService);
  const planOperationService = container.get(PlanOperationService);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, formState: { isDirty, isValid }, setValue, setError } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      sourceAccount: '',
      sourceAmount: '',
      targetAccount: '',
      targetAmount: ''
    }
  });

  useEffect(() => {
    combineLatest([accountService.getAll(), userService.get()]).subscribe(([accounts, user]) => {
      setAccounts(accounts);
      setValue('sourceAccount', accounts[0].id);
      setValue('targetAccount', user.activeAccountId);
    });
  }, [accountService, userService, setValue]);

  const disabled = !isDirty || !isValid || loading;

  return <Popup
    onHandle={() => {
      const subject = new Subject<boolean>();

      const done = (ok: boolean) => {
        setLoading(false);
        subject.next(!ok);
        subject.complete();
      };

      setLoading(true);
      handleSubmit(async ({ sourceAccount, sourceAmount, targetAccount, targetAmount }: FormData) => {

        if (sourceAccount === targetAccount) {
          setError('targetAccount', { message: `Source and target accounts can't be the same` });
          return done(false);
        }

        await planOperationService.transferToAccount({
          sourceAccountId: sourceAccount,
          sourceAmount: fixedAmount(sourceAmount),
          targetAccountId: targetAccount,
          targetAmount: fixedAmount(targetAmount)
        }, { accounts });

        onTransfer();
        done(true);
      })();
      return subject.asObservable();
    }}
    onClose={onClose}
    submitButtonTitle="Transfer"
    cancelButtonTitle="Cancel"
    submitButtonDisabled={disabled}
    open={open}
    title="Make Transfer">
    <form>
      <Controller name="sourceAccount" control={control}
        rules={{
          required: `It's required!`
        }}
        render={({ field, fieldState: { invalid, error } }) =>
          <FormControl fullWidth error={invalid}>
            <InputLabel>Source account</InputLabel>
            <Select {...field}>
              {accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
            </Select>
            {invalid && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>} />

      <Box mb={2} />
      <Controller name="sourceAmount" control={control}
        rules={{
          validate: checkMoney
        }}
        render={({ field, fieldState: { invalid, error } }) =>
          <TextField label="Source amount"
            fullWidth
            error={invalid}
            helperText={error?.message}
            {...field} />} />

      <Box mb={2} />
      <Controller name="targetAccount" control={control}
        rules={{
          required: `It's required!`
        }}
        render={({ field, fieldState: { invalid, error } }) =>
          <FormControl fullWidth error={invalid}>
            <InputLabel>Target account</InputLabel>
            <Select {...field}>
              {accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
            </Select>
            {invalid && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>} />
      <Box mb={2} />
      <Controller name="targetAmount" control={control}
        rules={{
          validate: checkMoney
        }}
        render={({ field, fieldState: { invalid, error } }) =>
          <TextField label="Target amount"
            fullWidth
            error={invalid}
            helperText={error?.message}
            {...field} />} />

      <Box mb={2} />
    </form>
  </Popup>
}