import React, { ReactElement, useState } from 'react';
import { Paper, Typography, Grid, Box, styled, useTheme, Theme } from "@material-ui/core";
import { toMoney } from '@ivorobioff/shared';
import AmountEditor, { AmountEditorHandler, AmountEditorValidator } from '../../parts/AmountEditor';
import Preference from '../../../models/Preference';
import { OperationIntent } from '../../../models/Operation';
import { IconBaseProps } from 'react-icons';

const IconHolder = styled(Grid)({
  textAlign: 'center'
});

const InfoSection = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(0.5)
}));

const InfoContent = styled(Typography)<Theme, { editable: 0 | 1 }>(({ editable }) => ({
  display: 'inline',
  ...(editable ? {
    borderBottomStyle: 'dotted',
    borderBottomWidth: 'thin',
    cursor: 'pointer'
  } : {})
}));

const StatPaper = styled(Paper)(({ theme }) => ({
  height: 100,
  padding: theme.spacing(2)
}));

interface AmountStatProps {
  amount: number;
  title: string;
  icon: (props: IconBaseProps) => ReactElement,
  editable?: {
    defaultIntent?: OperationIntent,
    onHandle: AmountEditorHandler,
    onValidate?: AmountEditorValidator
  };
  preference?: Preference;
  action?: ReactElement
}

export default function AmountStat({
  amount,
  title,
  icon,
  editable,
  preference,
  action,
}: AmountStatProps) {
  const [editorOpen, setEditorOpen] = useState(false);
  const theme = useTheme();

  return <StatPaper>
    <Grid container>
      <IconHolder xs={5} item>
        {icon({ size: 60, color: theme.palette.grey[500] })}
      </IconHolder>
      <Grid xs={7} item>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flexGrow={1}>
            <Typography component="h2" variant="h6" color="primary">{title}</Typography>
            <InfoSection>
              <InfoContent onClick={editable ? () => setEditorOpen(true) : undefined}
                editable={editable ? 1 : 0}
                variant="body1"
                color={amount < 0 ? 'error' : 'initial'}>
                {toMoney(amount)}
              </InfoContent>
            </InfoSection>
          </Box>
          {action && <Box>{action}</Box>}
        </Box>
      </Grid>
    </Grid>

    {editable && <AmountEditor defaultIntent={editable.defaultIntent}
      preference={preference}
      open={editorOpen}
      title={title}
      onClose={() => setEditorOpen(false)}
      onValidate={editable.onValidate}
      onHandle={editable.onHandle}
    />}
  </StatPaper>
}
