import Operation from '../models/Operation';
import { calculateAmount } from '../random/utils';
import { Container } from '@ivorobioff/shared';
import { SavingService } from './SavingService';
import Saving from '../models/Saving';

export default class SavingOperationService {
  private savingService: SavingService;

  constructor(container: Container) {
    this.savingService = container.get(SavingService);
  }

  validate(saving: Saving, operation: Operation) {
    return calculateAmount(saving.amount, operation) < 0
      ? 'Result must be greater than, or equal to 0'
      : undefined
  }

  async submit(saving: Saving, operation: Operation): Promise<number> {
    const calculatedAmount = calculateAmount(saving.amount, operation);
    await this.savingService.updateAmountAsync(saving.id, calculatedAmount, operation.note);
    return calculatedAmount;
  }
}