import Debt, { DebtToCreate, DebtToUpdate } from "../models/Debt";
import { Observable } from "rxjs";
import { HttpCommunicator, Container } from "@ivorobioff/shared";


export class DebtService {

  private http: HttpCommunicator;

  constructor(container: Container) {
    this.http = container.get('https');
  }

  getAll(): Observable<Debt[]> {
    return this.http.get('/debts');
  }

  updateAmount(id: string, amount: number, note?: string): Observable<any> {
    return this.http.patch('/debts/' + id, { amount, note });
  }

  updateAmountAsync(id: string, amount: number, note?: string): Promise<void> {
    return new Promise((resolve, reject) =>
      this.updateAmount(id, amount, note).subscribe(resolve, reject));
  }

  update(id: string, updates: DebtToUpdate): Observable<any> {
    return this.http.patch('/debts/' + id, updates);
  }

  create(debt: DebtToCreate): Observable<Debt> {
    return this.http.post('/debts', debt);
  }

  remove(id: string): Observable<any> {
    return this.http.delete('/debts/' + id);
  }
}