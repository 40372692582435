import React, { Component, Fragment } from 'react';
import {
  DataView,
  DataViewAction,
  DataViewColumn,
  DataViewCellFormat,
  cloneArray,
  cloneArrayExcept,
  cloneArrayWith,
  cloneWith,
  readField,
  transferTo,
  DataPaper,
  DataActionArea,
  Confirmation,
  DataFormControl,
  DataFormErrors,
  DataFormResult,
  PopupForm,
  checkMoney,
  toMoney,
  toNumber,
  Container
} from '@ivorobioff/shared';
import Debt, { DebtToCreate, DebtToUpdate } from "../../models/Debt";
import AmountEditor from "../parts/AmountEditor";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { DebtService } from "../../services/DebtService";
import { tap } from 'rxjs/operators';
import DebtOperationService from '../../services/DebtOperationService';
import Operation, { OperationIntent } from '../../models/Operation';
import { checkUniqueByName } from '../../validation/entry-validators';

export interface DebtsPageProps {
  container: Container;
}

export interface DebtsPageState {
  data: Debt[];
  remove?: {
    debt: Debt;
    open: boolean;
  },
  edit?: {
    debt: Debt;
    open: boolean;
    controls: DataFormControl[];
  },
  create?: {
    open: boolean;
    controls: DataFormControl[];
  },
  amountEditor?: {
    open: boolean;
    title: string;
    debt: Debt;
  }
}

class DebtsPage extends Component<DebtsPageProps, DebtsPageState> {

  private debtService: DebtService;
  private debtOperationService: DebtOperationService;

  columns: DataViewColumn[] = [
    {
      name: 'name'
    },
    {
      name: 'amount',
      format: DataViewCellFormat.MONEY,
      onClick: debt => this.openAmountEditor(debt)
    }
  ];

  actions: DataViewAction[] = [{
    icon: <AiOutlineEdit />,
    onClick: debt => {
      this.setState({
        edit: {
          open: true,
          debt,
          controls: this.defineEditorControls(debt)
        }
      });
    }
  }, {
    icon: <AiFillDelete />,
    onClick: (debt: Debt) => {
      this.setState({
        remove: {
          open: true,
          debt
        }
      });
    }
  }];

  constructor(props: DebtsPageProps) {
    super(props);

    this.debtService = props.container.get(DebtService);
    this.debtOperationService = props.container.get(DebtOperationService);

    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.debtService.getAll().subscribe(data => {
      this.setState({ data })
    }, console.error);
  }

  render() {

    const { data } = this.state;

    return (<Fragment>
      <DataPaper>
        <DataView
          title="Debts"
          data={data}
          actions={this.actions}
          columns={this.columns} />
        <DataActionArea onCreate={this.openCreator.bind(this)} />
      </DataPaper>
      {this.state.remove && (<Confirmation
        onClose={this.closeRemoveConfirmation.bind(this)}
        onHandle={this.handleRemoveConfirmation.bind(this)}
        confirmButtonTitle="Proceed"
        open={readField(this.state, 'remove.open')}
        title={`${readField(this.state, 'remove.debt.name')} - Delete`}>
        {`You are about to delete "${readField(this.state, 'remove.debt.name')}". Do you want to proceed?`}
      </Confirmation>)}

      {this.state.edit && (<PopupForm
        onValidate={this.validateEditor.bind(this)}
        controls={readField(this.state, 'edit.controls')}
        onClose={this.closeEditor.bind(this)}
        onSubmit={this.submitEditor.bind(this)}
        open={readField(this.state, 'edit.open')}
        title={`${readField(this.state, 'edit.debt.name')} - Update`} />)}

      {this.state.create && (<PopupForm
        onValidate={this.validateCreator.bind(this)}
        controls={readField(this.state, 'create.controls')}
        onClose={this.closeCreator.bind(this)}
        onSubmit={this.submitCreator.bind(this)}
        open={readField(this.state, 'create.open')}
        title="Debt - Create" />)}

      {this.state.amountEditor && (<AmountEditor
        onHandle={this.handleAmountEditor.bind(this)}
        onValidate={this.validateAmountEditor.bind(this)}
        defaultIntent={OperationIntent.Minus}
        title={this.state.amountEditor!.title}
        open={this.state.amountEditor!.open}
        onClose={this.closeAmountEditor.bind(this)} />)}
    </Fragment>);
  }

  validateAmountEditor(operation: Operation) {
    return this.debtOperationService.validate(this.state.amountEditor!.debt, operation);
  }

  async handleAmountEditor(operation: Operation) {
    const { debt } = this.state.amountEditor!;
    debt.amount = await this.debtOperationService.submit(debt, operation);
    this.setState({ data: [...this.state.data] });
  }

  openAmountEditor(debt: Debt) {
    this.setState({
      amountEditor: {
        open: true,
        debt,
        title: `${debt.name} - Amount`
      }
    });
  }

  closeAmountEditor() {
    this.setState({
      amountEditor: cloneWith(this.state.amountEditor, {
        open: false
      })
    });
  }

  validateEditor(result: DataFormResult): DataFormErrors {
    return checkUniqueByName(result['name'], this.state.data, { entry: this.state.edit?.debt });
  }

  validateCreator(result: DataFormResult): DataFormErrors {
    return checkUniqueByName(result['name'], this.state.data);
  }

  closeRemoveConfirmation() {
    this.setState({
      remove: cloneWith(this.state.remove, {
        open: false
      })
    });
  }

  handleRemoveConfirmation() {

    let debt = readField<Debt>(this.state, 'remove.debt');

    return this.debtService.remove(debt.id).pipe(
      tap(() => {
        this.setState({
          data: cloneArrayExcept(this.state.data, debt)
        });
      })
    );
  }

  closeEditor() {
    this.setState({
      edit: cloneWith(this.state.edit, {
        open: false
      })
    });
  }

  openCreator() {
    this.setState({
      create: cloneWith(this.state.create, {
        open: true,
        controls: this.defineCreatorControls()
      })
    });
  }

  closeCreator() {
    this.setState({
      create: cloneWith(this.state.create, {
        open: false
      })
    });
  }

  submitEditor(data: DataFormResult) {

    let debt = readField<Debt>(this.state, 'edit.debt');

    return this.debtService.update(debt.id, data as DebtToUpdate).pipe(
      tap(() => {
        transferTo(data, debt);
        this.setState({ data: cloneArray(this.state.data) });
      })
    )
  }

  submitCreator(data: DataFormResult) {
    return this.debtService.create(data as DebtToCreate).pipe(
      tap(debt => {
        this.setState({
          data: cloneArrayWith(this.state.data, debt)
        });
      })
    );
  }

  private defineEditorControls(debt: Debt): DataFormControl[] {
    return [{
      type: 'text',
      label: 'Name',
      name: 'name',
      required: true,
      value: debt.name
    }];
  }

  private defineCreatorControls(): DataFormControl[] {
    return [{
      type: 'text',
      label: 'Name',
      name: 'name',
      required: true
    }, {
      type: 'text',
      label: 'Amount',
      name: 'amount',
      required: true,
      validate: checkMoney,
      convertOut: toNumber,
      convertIn: toMoney
    }];
  }
}

export default DebtsPage;