export enum OperationIntent {
  Minus = 1,
  Plus = 2,
  AsIs = 3
}

export default interface Operation {
  amount: number;
  intent: OperationIntent;
  note?: string;
}