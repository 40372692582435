import { DataFormErrors } from '@ivorobioff/shared';
import Entry from '../models/Entry';

export function isUniqueByName(name: string, entries: Entry[], entry?: Entry) {
  name = name.trim().toLowerCase();
  return !entries.find(s => s.name.trim().toLowerCase() === name && (!entry || entry.id !== s.id));
}

export function checkUniqueByName(
  name: string,
  entries: Entry[],
  { entry, field = 'name' }: { entry?: Entry, field?: string } = {}): DataFormErrors {
  if (!isUniqueByName(name, entries, entry)) {
    return {
      [field]: 'It already exists'
    }
  }

  return {};
}